import React from 'react'
import {Link} from 'gatsby'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import details1 from '../assets/images/seo-analysis.png'
import project2 from '../assets/images/projects/project2.jpg'
import charts from '../assets/images/services/charts.jpg'
import icon1 from '../assets/images/services/service-icon1.png'
import icon2 from '../assets/images/services/service-icon2.png'
import icon3 from '../assets/images/services/service-icon3.png'
 
const Details = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Data analysis" 
                homePageText="Services" 
                homePageUrl="/services" 
                activePageText="Data analysis" 
            />
            <ServiceDetailsContent />
            <RelatedProjects />
            <Footer />
        </Layout>
    );
}

const ServiceDetailsContent = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-image">
                            <img src={details1} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <span className="sub-title">Data analysis</span>
                            <h3>From data to knowledge</h3>
                            <p>We dig the mines of information composed by your data. You might not even be fully aware of the potential richness you have at your disposal, we still work hard to look for those information nuggets you surely have. 
</p>

                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h3>Features</h3>
                                        <ul>
                                            <li>Data Analysis</li>
                                            <li>Data cleaning</li>
                                            <li>Machine Learning</li>
                                            <li>Data Mining</li>
                                            <li>Statistical analysis</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <p>All organizations possess and produce a large amount of data. From this data it is often possible to derive important and useful information for the mission of the organization, but it is necessary to analyze it with more or less sophisticated quantitative techniques.  Starting from a basic statistical analysis, we try to find correlations and patterns hidden in the data, with a progressively more complex approach, using increasingly sophisticated techniques up to the application of machine learning and artificial intelligence algorithms.</p>.
                            <h3>Technologies utilizzate</h3>
                            <ul className="technologies-features">
                                <li><span>Jupiter</span></li>
                                <li><span>Python</span></li>
                                <li><span>Javascript</span></li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebar />
                    </div>
                </div>
            </div>
        </section>
    )
}

const ServiceSidebar = () => {
    return (
        <div className="services-details-info">
            <ul className="services-list">
                <li>
                    <Link to="/app-development" >
                       App development
                    </Link>
                </li>
                <li>
                    <Link to="/digital-transformation">
                        Digital transformation
                    </Link>
                </li>
                <li>
                    <Link to="/social-balance-monitoring-evaluation">
                        Social balance, monitoring and evaluation
                    </Link>
                </li>
                <li>
                    <Link to="/case-management">
                    Case management
                    </Link>
                </li>
                <li>
                    <Link to="/data-analysis" className="active">
                        Data Analysis
                    </Link>
                </li>
                <li>
                    <Link to="/artificial-intelligence">
                        Artificial intelligence
                    </Link>
                </li>
            </ul>
        </div>
    )
}

const RelatedProjects = () => {
    return (
        <section className="services-area pt-100 pb-70 bg-f1f8fb">
            <div className="container">
                <div className="section-title">
                    <h2>Developed projects</h2>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box ">
                            <div className="icon">
                                <img src={icon1} alt="about" />
                            </div>
                            <h3>
                                <Link to="#">
                                    PICAPS
                                </Link>
                            </h3>
                            <p>Data analysis on questionnaires collected in the PICAPS project in Burkina Faso with CIAI ONLUS</p>

                            <Link to="/project/picaps/" className="read-more-btn">
                                Read more<i className="flaticon-right"></i>
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={icon2} alt="about" />
                            </div>
                            <h3>
                                <Link to="/project/kap-tanzania-kenya">
                                    KAP Survey
                                </Link>
                            </h3>
                            <p>KAP data collection and analysis in Tanzania and Kenya, against the COVID-19 pandemic</p>
                            
                            <Link to="/project/kap-tanzania-kenya" className="read-more-btn">
                            Read more <i className="flaticon-right"></i>
                            </Link>
                        </div>
                    </div>    
                </div>
            </div>
        </section>
    )
}

export default Details
